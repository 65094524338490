import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  DialogBackground,
  DialogCancelButton, DialogContent,
  DialogFooter,
  DialogOkButton
} from './Elements'
import { DialogWithAnimationProps } from './types'
import { classNames, getSearchParams } from '@tmap-web-lib/utils'
import { CSSTransition } from 'react-transition-group'
import { useLogBox } from '../../hooks'
import { TERMS_TELE_MARKETING_LOG_PRESET } from '../../hooks/logbox/preset'
import { PageIdSearchParams } from '../../providers/page-id-context'
import isEmpty from 'lodash-es/isEmpty'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useSelector } from 'react-redux'
import { selectSettingsEuk, selectSettingsIsCarLifeTermsAgreed } from '../../store/settings'
import { useGetTermsAgreement, useUpdateTermsAgreement } from '../../react-query'
import { TermsInfoType, useGetTermsInfo } from '../../pages/Etc/hook'
import { TermsAgreements } from '@tmap-web-lib/remote-api-client/frontman'
import { TermsContents } from '../../pages/Etc'

function DialogSingleTerms(props: DialogWithAnimationProps) {
  const {
    optionalTermsGroupData,
    wrapperClassName,
    title,
    okText,
    cancelText,
    okClassName,
    cancelClassName,
    footerClassName,
    onOk,
    onCancel,
  } = props
  const euk = useSelector(selectSettingsEuk)
  const ref = useRef(null)
  const backgroundRef = useRef(null)
  const [eventLog, exposeLog] = useLogBox(['event', 'expose'], {
    attributesPreset: TERMS_TELE_MARKETING_LOG_PRESET.ATTRIBUTES,
    customFieldsPreset: TERMS_TELE_MARKETING_LOG_PRESET.CUSTOM_FIELDS,
    withEuk: true
  })
  const isCarLifeTermsAgreed = useSelector(selectSettingsIsCarLifeTermsAgreed)

  const [modalIsOpen, setModalIsOpen] = useState(true)
  const [isDisabled, setIsDisabled] = useState(true)
  const [termsList, setTermsList] = useState<TermsInfoType[]>([])
  const [targetTermsCode, setTargetTermsCode] = useState('')
  // 연타를 막기 위한 상태값
  const [inactive, setInactive] = useState(false)

  const { data: termsAgreementData } = useGetTermsAgreement({
    termsType: 'UBS01',
    termsAllowCode: targetTermsCode,
    enabled: isCarLifeTermsAgreed === true && !!targetTermsCode,
  })
  const updateTermsAgreement = useUpdateTermsAgreement()

  const termsAgreements = useMemo(() => {
    return termsAgreementData?.termsAgreements || []
  }, [termsAgreementData])

  const termsInfo = useGetTermsInfo({
    termsGroupData: optionalTermsGroupData,
    termsAgreements,
  })

  const handleChecked = useCallback((termsList: TermsInfoType[]) => {
    setTermsList(termsList)
  }, [])

  const handleOk = useCallback(() => {
    setInactive(true)
    if (!isDisabled) {
      if (termsList) {
        const parentTermsList = termsList.filter((terms) => terms.subTermsList)
        const subTermsList = parentTermsList[0].subTermsList?.filter((terms) => terms)
        const updateTermsList: Pick<TermsAgreements, 'allowCode' | 'allowYn' | 'allowTitle'>[] = []

        if (termsList) {
          termsList.forEach((terms) => {
            updateTermsList.push({
              'allowCode': terms.termsType ?`${terms.termsType}${terms.termsCode}` : `${terms.termsCode}`,
              'allowTitle': `${terms.title}`,
              'allowYn': terms.isChecked ? 'Y' : 'N',
            })
          })
          subTermsList?.forEach((terms) => {
            updateTermsList.push({
              'allowCode': `${terms.termsType}${terms.termsCode}`,
              'allowTitle': `${terms.title}`,
              'allowYn': terms.isChecked ? 'Y' : 'N',
            })
          })
        }

        updateTermsAgreement.mutateAsync(updateTermsList.map((terms) => terms), {
          onSuccess: () => {
            eventLog().set('action_id', 'tap.agree').send()
          },
          onError: () => {
            onOk?.()
          },
          onSettled: () => {
            setModalIsOpen(false)
          }
        })
      }

    }
  }, [isDisabled, termsList, eventLog, updateTermsAgreement, onOk])

  const handleCancel = useCallback(() => {
    setModalIsOpen(false)
    eventLog().set('action_id', 'tap.disagree').send()
    setTimeout(async () => {
      onCancel?.()
    }, 500)
  }, [onCancel, eventLog])

  useEffect(() => {
    if (termsInfo) {
      setTermsList(termsInfo)
    }
  }, [termsInfo])

  const displayTerms = useMemo(() => {
    if (termsList?.length) {
      return (
        <>
          <DialogContent
            title={title}
            content={
              <>
                <TermsContents
                  termsList={termsList}
                  handleChecked={handleChecked}
                  eventLog={eventLog}
                  isSingleTerms
                />
              </>
            }
          />
          <DialogFooter
            className={classNames(footerClassName, 'type_flex_between')}
            okButton={
              <DialogOkButton
                className={classNames(isDisabled ? 'clickable-disabled' : okClassName, inactive ? 'inactive' : undefined)}
                disabled={isDisabled || inactive}
                styleFontWeight={!isDisabled ? 'bold' : 'normal'}
                onClick={handleOk}
              >
                {okText}
              </DialogOkButton>
            }
            cancelButton={
              <DialogCancelButton
                className={cancelClassName}
                onClick={handleCancel}
              >
                {cancelText}
              </DialogCancelButton>
            }
          />
        </>
      )
    }
  }, [termsList, isDisabled, inactive, cancelClassName, cancelText, footerClassName, handleChecked, handleCancel, handleOk, okClassName, okText, title, eventLog])

  useEffect(() => {
    const searchParams = getSearchParams<PageIdSearchParams>()
    if (!isEmpty(searchParams) && searchParams.extra) {
      const extraObject = JSON.parse(searchParams.extra as string)
      const extraCustomFields = extraObject.logbox

      if (extraCustomFields) {
        Object.keys(extraCustomFields).forEach(key => {
          TERMS_TELE_MARKETING_LOG_PRESET.CUSTOM_FIELDS.push([key, extraCustomFields[key]])
        })
      }
    } else {
      TERMS_TELE_MARKETING_LOG_PRESET.CUSTOM_FIELDS.push(['type', 'tab'], ['origin', 'tab'])
    }
    exposeLog().send()
    TmapApp.recordEvent({ name: 'drivingscore_Agreement', json: { euk: euk } })
  }, [euk, exposeLog])

  // useEffect(() => {
  //   if (optionalTermsGroupData && optionalTermsGroupData.termsAgreeList) {
  //     setTermsList(optionalTermsGroupData.termsAgreeList)
  //   }
  // }, [optionalTermsGroupData])

  useEffect(() => {
    if (termsList.length) {
      setTargetTermsCode(termsList.filter(item => !item.parentTermsCode)[0].termsCode)
    }
  }, [termsList])

  useEffect(() => {
    if (termsList[0]?.isChecked) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [termsList])

  if (!termsList.length) return null

  return (
    <>
      <CSSTransition
        classNames="fade-out"
        unmountOnExit={true}
        timeout={800}
        in={modalIsOpen}
        nodeRef={backgroundRef}
        appear
      >
        <DialogBackground className={wrapperClassName} ref={backgroundRef}>
          <CSSTransition
            classNames="slide-up"
            unmountOnExit={true}
            timeout={400}
            in={modalIsOpen}
            nodeRef={ref}
            appear
          >
            <div ref={ref} className={classNames('popup_wrap', 'popup_bottom', 'popup_terms_form')}>
              <div className={classNames('popup_cont')}>
                {displayTerms}
              </div>
            </div>
          </CSSTransition>
        </DialogBackground>
      </CSSTransition>
    </>
  )
}

export default DialogSingleTerms
export { DialogSingleTerms }
