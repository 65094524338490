import 'react-app-polyfill/stable'
import 'swiper/swiper.scss'
import './styles/index.scss'
import { datadogRum } from '@datadog/browser-rum'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { Provider as DialogManagerProvider } from '@tmap-web-lib-close/dialog-manager/react-router'
import { init as initLogbox } from '@tmap-web-lib-close/logbox-client'
import { getSearchParams } from '@tmap-web-lib/utils'
import { createBrowserHistory } from 'history'
import toLower  from 'lodash-es/toLower'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'
import App from './App'
import { store } from './store'
import { setResponse } from './store/app'
import {
  setAccessKey,
  setCarrierName,
  setDeviceId,
  setDevToolsAccessKey,
  setEuk,
  setSessionId,
  setAccessKeyExpired,
} from './store/settings'
import { pickAccessKey } from './store/settings/storage-utils'
import { init as initFrontmanClient } from '@tmap-web-lib/remote-api-client/frontman'
import mixpanel, { init as initMixpanel } from 'mixpanel-browser'
import { UAParser } from 'ua-parser-js'
import smoothscroll from 'smoothscroll-polyfill'
import { initMockClient } from './api'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { lazyRetry } from './utils/lazy-retry'
import { trackErrorLogs } from './utils'
import { AxiosError } from 'axios'
import { fetchAndActivate, getValue } from 'firebase/remote-config'
import { remoteConfig } from './config'

smoothscroll.polyfill()
Swiper.use([Pagination, Autoplay, Navigation])

const basename = `${(process.env.PUBLIC_URL || '')}`
const history = createBrowserHistory({ basename })
const DevTools = lazyRetry(() => import('./dev-tools/LifeTabDevTools'))
const ua = UAParser()
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // react query는 기본적으로 요청이 실패하면 3번의 재시도
      retry: 0,
      refetchOnWindowFocus: false,
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      // 최초 설정에서 useErrorBoundary: true 면 4XX 에러를 에러 객체로 반환해주지 않음. (각 query hooks에서 false로 줘도 변경되지 않음)
      useErrorBoundary: true,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      trackErrorLogs(error as AxiosError, 'tscore:error:onError')
    }
  })
})
const rootNode = document.getElementById('root')!
const getRtgUrl = (href: string) => {
  if (href.startsWith('https://tscore.tmobiweb.com')) {
    return href.replace(/^(https:\/\/)(tscore.*?\.)?(.*)/, '$1tscore-rtg.$3')
  } else if (href.startsWith('https://tscore-stg.tmobiweb.com')) {
    return href.replace(/^(https:\/\/)(tscore.*?\.)?(.*)/, '$1tscore-dtg.$3')
  }
  return
}

TmapApp.getUserSetting({ key: 'local.server_type' })
.then(serverType => {
  if (serverType === 'rtg') {
    const href = window.location.href
    const rtgUrl = getRtgUrl(href)

    if (!!rtgUrl) {
      window.location.replace(rtgUrl)
      return
    }
  }
  const isPrd = process.env.REACT_APP_BUILD_ENV === 'PRD' && serverType !== 'rtg'

  Promise.all([
    TmapApp.getAccessKey(),
    TmapApp.getDeviceId(),
    TmapApp.getCarrierName(),
    TmapApp.getEUK(),
    TmapApp.getSessionId(),
  ])
  .then(([accessKey, deviceId, carrierName, euk, sessionId]) => {
    // 쿼리스트링 ak, apiKey는 실질적으로 웹브라우저 테스트용.
    // 통신사 이름이 2바이트 문자열(일본어, 중국어)인 경우 브라우저에서 API 에러 발생하여 영문, 숫자를 제외한 나머지는 ETC로 변경(TMAPWEB-1583)
    const reg = /^[a-zA-Z\d]*$/
    const carrier = reg.test(carrierName) ? carrierName : 'ETC'
    store.dispatch(setAccessKey(pickAccessKey(accessKey || getSearchParams().ak)))
    store.dispatch(setCarrierName(carrier))
    store.dispatch(setDeviceId(deviceId))
    store.dispatch(setEuk(euk))
    store.dispatch(setSessionId(sessionId))

    initFrontmanClient({
      hostUrl: process.env.REACT_APP_FRONTMAN_URL,
      headers: {
        Carrier: carrier,
        'Device-Id': deviceId,
        'App-Code': 'TMAP',
        'Os-Type': TmapApp.env.isAndroid ? 'AND' : 'IOS',
        'App-Version': TmapApp.env.appVersion,
        'Os-Version': ua.os.version || '',
        'Model-No': ua.device.model || '',
        Using: 'WEB_LIFE',
        Euk: euk,
      },
      timeout: 35000,
      onRequest(config) {
        config.headers.AccessKey = store.getState().settings.accessKey
        return config
      },
      onResponse(response) {
        store.dispatch(setResponse(response.data))
        return response
      },
      onAccessKeyChanged(newAccessKey: string) {
        if (newAccessKey) {
          TmapApp.updateAccessKey({ key: newAccessKey })
          store.dispatch(setAccessKey(newAccessKey))
          if (store.getState().settings.devToolsAccessKey) {
            store.dispatch(setDevToolsAccessKey(newAccessKey))
          }
        }
      },
      onAccessKeyExpired(e) {
        store.dispatch(setAccessKeyExpired())
        trackErrorLogs(e, 'tscore:error:frontman:onAccessKeyExpired')
      },
      onError(e) {
        trackErrorLogs(e, 'tscore:error:frontman:onError')
      }
    })

    initMockClient({
      onRequest(config) {
        config.headers.AccessKey = store.getState().settings.accessKey
        return config
      },
      onResponse(response) {
        store.dispatch(setResponse(response.data))
        return response
      },
      onAccessKeyChanged(newAccessKey: string) {
        if (newAccessKey) {
          TmapApp.updateAccessKey({ key: newAccessKey })
          store.dispatch(setAccessKey(newAccessKey))
          if (store.getState().settings.devToolsAccessKey) {
            store.dispatch(setDevToolsAccessKey(newAccessKey))
          }
        }
      },
      onAccessKeyExpired(e) {
        store.dispatch(setAccessKeyExpired())
      },
    })

    initLogbox({
      env: isPrd ? 'PROD' : 'STG',
      logBoxEnv: {
        logVersion: `1.0-${toLower(process.env.REACT_APP_BUILD_ENV)}`,
        appId: 'tmap',
        serviceId: 'drivinglife',
        token: isPrd ? 'F5CD39ECE530' : '3AF19605D63E',
      },
      appEnv: {
        appVersion: TmapApp.env.appVersion,
        deviceId,
        carrier: carrier,
        ip: '',
        sessionId: sessionId || '',
      },
    })

    initMixpanel( isPrd ? 'cafac3a4974386155257d177fc48437a' : '7600c413c33bbd7e3742b10f1a522f0d',{
      debug : !isPrd,
      ignore_dnt: !isPrd,
      loaded: (mixpanel) => {
        mixpanel.identify(euk)
        mixpanel.register({
          $app_version: TmapApp.env.appVersion,
          $carrier: carrier,
          $manufacterer: ua.device.vendor,
          $model: ua.device.model,
          $os_version: ua.os.version,
          device_id: deviceId,
          session_id : sessionId,
        })
      }
    })


    fetchAndActivate(remoteConfig)
      .then(() => {
        const dataDogSampleRate = getValue(remoteConfig, 'life_datadog_sample_rate').asNumber()
        const dataDogSessionReplayRate = getValue(remoteConfig, 'life_datadog_session_replay').asNumber()
        const targetList = getValue(remoteConfig, 'life_datadog_target_list').asString()
        const datadogTargetList = targetList.split(',')
        const isTargetUser = datadogTargetList.findIndex((target) => target === deviceId) !== -1

        if (isTargetUser) {
          datadogRum.init({
            applicationId: 'e3eed284-543a-4d8e-99af-1638b66c350f',
            clientToken: 'pub3bb5bc9b1bbd73867bc770639c3291a9',
            site: 'datadoghq.com',
            service: 'tmap-life',
            env: toLower(process.env.REACT_APP_BUILD_ENV),
            version: `${process.env.BUILD_INFO}#${process.env.BUILD_NUMBER}`,
            sampleRate: 100,
            sessionReplaySampleRate: 100,
            trackInteractions: true,
          })
          datadogRum.startSessionReplayRecording()
        } else {
          datadogRum.init({
            applicationId: 'e3eed284-543a-4d8e-99af-1638b66c350f',
            clientToken: 'pub3bb5bc9b1bbd73867bc770639c3291a9',
            site: 'datadoghq.com',
            service: 'tmap-life',
            env: toLower(process.env.REACT_APP_BUILD_ENV),
            version: `${process.env.BUILD_INFO}#${process.env.BUILD_NUMBER}`,
            sampleRate: isPrd ? dataDogSampleRate ? Number(dataDogSampleRate) : 5 : 100,
            sessionReplaySampleRate: isPrd && dataDogSessionReplayRate ? Number(dataDogSessionReplayRate) : 0,
            trackInteractions: true,
          })
          Number(dataDogSessionReplayRate) && datadogRum.startSessionReplayRecording()
        }
      })
      .catch((error) => {
        mixpanel.track(error.response?.data ? `tscore:error:remoteConfig:INDEX:${error.response.data}` : 'tscore:error:remoteConfig:INDEX', { error: error })
        datadogRum.addError(error, {
          error: {
            ...error,
            request: error.request.response,
            message: error.response?.data ? `tscore:error:remoteConfig:INDEX:${error.response?.data}` : 'tscore:error:remoteConfig:INDEX',
          },
        })
      })

    ReactDOM.createRoot(rootNode).render(
      <React.StrictMode>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
              <>
                <Router history={history}>
                  <DialogManagerProvider history={history}>
                    <App/>
                    {!isPrd && <Suspense fallback=""><DevTools/></Suspense>}
                  </DialogManagerProvider>
                </Router>
                <ReactQueryDevtools position={'bottom-right'} />
              </>
          </QueryClientProvider>
        </Provider>
      </React.StrictMode>
    )
  })
})
