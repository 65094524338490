import { useCallback, useEffect } from 'react'
import { useLogBox, useOpenInsureLink, useOpenService, useOpenServiceUrl } from '../../../hooks'
import { usePageIdContext } from '../../../providers/page-id-context'
import useTidLogin from './use-tid-login'
import { InsurerCode } from '@tmap-web-lib/remote-api-client/frontman'

const otpPage = 'tmap://life?pageid=settings_otp'

type LogboxPreset = {
  attributesPreset: {
    page_type: string,
    page_id: string,
  }
  customFieldsPreset: [name: string, value: string][]
}

interface Props {
  logboxPreset: LogboxPreset
  insurerCode: InsurerCode | null
}

function useInsurance(props: Props) {
  const { logboxPreset, insurerCode } = props
  const { attributesPreset, customFieldsPreset } = logboxPreset

  const pageIdContext = usePageIdContext()
  const openServiceUrl = useOpenServiceUrl()
  const openInsureLink = useOpenInsureLink()
  const openService = useOpenService()
  const [eventLog, exposeLog] = useLogBox(['event', 'expose'], {
    attributesPreset,
    customFieldsPreset,
    withEuk: true,
  })
  const isTidLogin = useTidLogin()
  const insuranceId = insurerCode?.replace('INS_', '') || ''

  const handleOTP = useCallback(() => {
    eventLog()
      .set('action_id', 'tap.otp')
      .addCustomField('insurance_id', insuranceId)
      .send()
    openServiceUrl(otpPage)
  }, [eventLog, insuranceId, openServiceUrl])

  const handleClick = useCallback((link?: string, code?: InsurerCode) => {
    if (link) {
      eventLog()
        .set('action_id', 'tap.go_insur')
        .addCustomField('insurance_id', insuranceId)
        .send()
      if (code === 'INS_KB' || code === 'INS_CR') {
        openService(`tmap://webview?service=insurance&id=${code}`)
      } else {
        openInsureLink(link)
      }
    }
  }, [eventLog, openInsureLink, openService, insuranceId])

  useEffect(() => {
    if (insuranceId) {
      const extraCustomFields = pageIdContext.extraObject.logbox
      if (extraCustomFields) {
        Object.keys(extraCustomFields).forEach(key => {
          customFieldsPreset.push([key, extraCustomFields[key]])
        })
      }
      exposeLog().addCustomField('insurance_id', insuranceId).send()
    }
  }, [exposeLog, pageIdContext, customFieldsPreset, insuranceId])

  return {
    pageIdContext,
    isTidLogin,
    eventLog,
    handleOTP,
    handleClick,
  }
}

export default useInsurance
